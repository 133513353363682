<template>
    <div style="background-color: white;min-height: 650px;padding: 20px;box-sizing: border-box;">
        <div class="flex items-center">
            <i class="el-icon-arrow-left font-bold text-xl cursor-pointer" style="color: #999999" @click="goBack" />
            <p class="product_name">{{ project_info.project_ename }}</p>
        </div>

        <div class="product-content">
            <img class="product-logo" :src="project_info.project_logo">
            <div class="middle-content">
                <p class="product_name" style="font-size: 14px;margin-bottom: 4px">{{ project_info.project_ename }}</p>
                <div style="display: flex;gap: 5px">
                    <div v-if="project_info.earlybird_deadline_date" class="price-container">
                        <div>
                            <p class="font_red font_bold">Early Bird Registration</p>
                            <p class="price-date">DDL: {{ $dayjs(project_info.earlybird_deadline_date).format("MMMM DD,YYYY") }}</p>
                        </div>
                        <p class="price">Fee: {{ project_info.project_earlybird_price }} {{
                            project_info.project_price_currency }}</p>
                    </div>
                    <div class="price-container">
                        <div>
                            <p class="font_red font_bold">Normal Registration</p>
                            <p class="price-date">DDL: {{ $dayjs(project_info.deadline_date).format("MMMM DD, YYYY") }}
                            </p>
                        </div>
                        <p class="price">Fee: {{ project_info.project_price == 0 ? 'Free' : project_info.project_price +
                            ' ' + project_info.project_price_currency }}</p>
                    </div>
                    <div v-if="project_info.project_price !== 0" class="price-container">
                        <div>
                            <p class="font_red font_bold">Fee Reduction</p>
                        </div>
                        <p class="price-reduction font_red"><span @click="showFeeReduction"
                                :class="price_policy ? 'font_under_line' : ''" style="font-family: centurygothic;">{{
                                    reduction_level1 }} to {{ reduction_level8 }} {{ project_info.project_price_currency
                                }}</span></p>
                    </div>
                </div>
            </div>
            <div class="right-content">
                <div v-if="!checkStartProject()">
                    <p style="font-size: 12px;display: inline-block;margin-right: 10px;">Registration opens on {{ convertChinaDateToEngDate(Date.parse(project_info.project_progress_start_date)) }}</p>
                    <div class="btn-disabled">Sign Up</div>
                </div>
                <div v-else>
                    <div class="btn-sign" v-if="checkEnableSign()" :class="checkSignResult == -1 ? 'btn-disabled' : ''"
                    @click="doSign">{{
                        checkSignResult == -1 ? 'Signed Up' : 'Sign Up' }}</div>
                    <div class="btn-disabled" v-if="checkSignClose()">Closed</div>
                    <p class="desc">This project is included in the EAE
                        Univesal Pass Portfolio. Consider purchasing the EAE Universal Pass for a better value
                        and access to more projects.</p>
                </div>
            </div>
        </div>
        <div style="margin-top: 30px;">
            <el-row :gutter="20">
                <el-col :span="16">
                    <div>
                        <!-- <p class="title">Introduction</p> -->
                        <!--Put the introduction below in el-tabs-->
                        <!-- select index 0 pane by default-->
                        <el-tabs v-model="activePane" @tab-click="handlePaneClick">
                            <el-tab-pane label="Introduction" name="introduction">
                                <div v-html="project_info.project_introduction" class="intro_container ql-snow ql-editor"></div>
                            </el-tab-pane>
                            <el-tab-pane label="Timeline" name="timeline">
                                <div class="timeline-container">
                                    <div class="timeline-note">
                                        <p><i>*Depends on the nature of the program, the entry in the timeline below either means entry of your exams (for academic test events) or entry of your projects/works/publications for review.</i></p>
                                        <p><i>**The date and time all refer to the designated local time for each continent. <b>Asia & Oceania: GMT+8, Europe & Africa: GMT+1, North & SouthAmerica: GMT-4.</b></i></p>
                                    </div>
                                    <div class="timeline-cycles">
                                        <div v-for="cycle in project_info.projectManage.entry_date" :key="cycle.name" class="timeline-cycle">
                                            <h3>{{cycle.name}}</h3>
                                            
                                            <el-table :data="[cycle]">
                                                <el-table-column prop="start_date" label="Start Date for Entry">
                                                    <template slot-scope="scope">
                                                        {{ $dayjs(scope.row.start_date).format('MMMM DD, YYYY') }}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column prop="ddl_date" label="Deadline for Entry">
                                                    <template slot-scope="scope">
                                                        {{ $dayjs(scope.row.ddl_date).format('MMMM DD, YYYY') }}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column prop="announce_date" label="Announcement Date">
                                                    <template slot-scope="scope">
                                                        {{ $dayjs(scope.row.announce_date).format('MMMM DD, YYYY') }}
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </div>
                                    </div>

                                    <div v-if="examTimes" class="exam-times">
                                        <h3>Exam Time</h3>
                                        <el-table :data="examTimes">
                                            <el-table-column prop="0" label="Name"></el-table-column>
                                            <el-table-column prop="1" label=" Start Time"></el-table-column>
                                            <el-table-column prop="2" label="End Time"></el-table-column>
                                        </el-table>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="Syllabus" name="syllabus" v-if="project_info.class_info">
                                <div class="syllabus-container">
                                    
                                    <div v-for="(value, key) in project_info.class_info" :key="key" class="syllabus-section">
                                        <h3>{{ key }}</h3>
                                        <ul>
                                            <li v-for="(item, index) in value" :key="index">
                                                <span v-if="typeof item === 'string'">{{ item }}</span>
                                                <template v-else-if="Array.isArray(item)">
                                                    <span>{{ item[0] }}</span>
                                                    <ul v-if="item.length > 1">
                                                        <li v-for="(subItem, subIndex) in item.slice(1)" :key="subIndex">
                                                            {{ subItem }}
                                                        </li>
                                                    </ul>
                                                </template>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="Sample Questions" name="sample_questions" v-if="project_info.sample_quizes && project_info.sample_quizes.length > 0">
                                <div class="sample-questions-container">
                                    <div v-for="(quiz, index) in project_info.sample_quizes" :key="index" class="sample-question">
                                        <h3>Sample Question {{ index + 1 }}</h3>
                                        <p class="question"><strong>Question:</strong> {{ quiz.Question }}</p>
                                        <ul class="options">
                                            <li><strong>A:</strong> {{ quiz['Option A'] }}</li>
                                            <li><strong>B:</strong> {{ quiz['Option B'] }}</li>
                                            <li><strong>C:</strong> {{ quiz['Option C'] }}</li>
                                            <li><strong>D:</strong> {{ quiz['Option D'] }}</li>
                                        </ul>
                                        <p class="correct-answer"><strong>Correct Answer:</strong> {{ quiz['Correct Answer'] }}</p>
                                        <p class="explanation"><strong>Explanation:</strong> {{ quiz.Explanation }}</p>
                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                        
                    </div>
                </el-col>
                <el-col :span="8">
                    <div>
                        <p class="title">Announcements & Updates</p>

                        <div class="announce-container">
                            <div v-for="(announce, index) in news" :key="index" class="announce-item"
                                @click="doShowNewsInfo(announce)">
                                <p class="announce-time">{{ $dayjs(announce.update_time * 1000).format('MMM D, YYYY') }}
                                </p>
                                <p class="announce-title">{{ announce.title }}</p>
                                <p class="announce-content" v-html="announce.content"></p>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-dialog :visible.sync="isShowDialog" title="View" width="80%">
            <div class="disable-mouse-events">
                <iframe :src="price_policy + '#toolbar=0'" width="100%" height="600px"></iframe>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="showNewsDialog" width="45%" :title="selectNews.title">
            <div style="margin: 10px">
                <p v-html="selectNews.content" style="color: #666;font-size: 14px;line-height: 20px;"></p>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    createStudentApplication,
    getNewsUpdateListByProject,
    getStudentReductionLevelByProject,
    getREProjectInfo, checkEnableStudentSignupProject,
    postRequest
} from '../../api/eae'
import {
    getUserId
} from '../../utils/store'
import '../../assets/common/font.css'
export default ({
    name: 'index',
    data() {
        return {
            project_id: this.$route.query.project_id,
            project_info: {},
            productPrice: {},
            reduction_level: 0,
            reduction_level1: 0,
            reduction_level8: 0,
            reduction_price: 0,
            price_policy: '',
            news: [],
            isShowDialog: false,
            checkSignResult: -3,
            signMsg: '',
            showNewsDialog: false,
            selectNews: {},
            panes: ['introduction','timeline','syllabus'],
            activePane: 'introduction'
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {

        convertChinaDateToEngDate(time){
            return this.$dayjs(time).format("MMMM DD,YYYY") 
        },

        checkStartProject() {
            if (this.project_info.project_progress_start_date) {
                let progress_start_date = this.project_info.project_progress_start_date
                let start_date = Date.parse(progress_start_date)
                console.log('start_date :' + start_date)
                let current_time = new Date().getTime()
                console.log('current_time :' + current_time)
                if (start_date >= current_time) {
                    return false
                }else{
                    return true
                }
            }
            return true
        },

        checkEnableSign() {
            if (this.project_info.deadline_date && this.project_info.deadline_date) {
                let deadline_date = this.project_info.deadline_date
                let current_time = new Date().getTime()
                if (deadline_date > current_time) {
                    return true
                }
            }
            return false
        },


        checkSignClose() {
            if (this.project_info.deadline_date && this.project_info.deadline_date) {
                let deadline_date = this.project_info.deadline_date
                let current_time = new Date().getTime()
                if (deadline_date < current_time) {
                    return true
                }
            }
            return false
            // project_info.deadline_date && project_info.deadline_date <= new Date.getTime()
        },

        async fetchData() {

            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            let projectResult = await getREProjectInfo(this.project_id)
            this.project_info = projectResult.data
            if(this.project_info.exam_time){
                this.examTimes = this.project_info.exam_time.split('\n').map(row => {
                    return row.split('|').map(item => item.trim());
                });
            }

            let newsResult = await getNewsUpdateListByProject(this.project_id)
            this.news = newsResult.data.sort((a, b) => {
                return b.update_time - a.update_time
            })

            let reductionLevelResult = await postRequest({
                function: "getStudentReductionLevelsByProject",
                student_id: getUserId(),
                project_id: this.project_id
            });
            let reduction_level = reductionLevelResult.data.reduction_level;

            let signupResult = await checkEnableStudentSignupProject(getUserId(), this.project_id)
            this.checkSignResult = signupResult.data.code
            this.signMsg = signupResult.data.msg

            for (let i = 0; i < this.project_info.price_reductions.length; i++) {
                let price = this.project_info.price_reductions[i]
                if (price.level == reduction_level) {
                    this.reduction_price = price.reduction_price
                    break
                }
            }
            this.reduction_level1 = this.project_info.price_reductions[0].reduction_price
            this.reduction_level8 = this.project_info.price_reductions[7].reduction_price
            this.price_policy = this.project_info.price_policy

            loading.close();
        },

        doSign() {

            if (this.checkSignResult == -1 || this.checkSignResult == -3) {
                return
            }
            if (this.checkSignResult == -2) {
                this.$confirm(this.signMsg, "Entry Pass Required", { type: "warning", confirmButtonText: "Confirm", showCancelButton: false }).then(() => {
                }).catch(() => {
                })
                return
            }

            createStudentApplication(getUserId(), this.project_id, 'EAE-Store').then((res) => {
                let result = res.data
                if (result.code == 0) {
                    //TODO胡老师：把My Programs里面的按钮Verify和Pay也搬过来。
                    this.$confirm('You have successfully submitted an application for ' + this.project_info.project_ename + '. Please go to "My Programs" and continue to complete your program registration by either veirfying a PASS or paying individually for the program. If you are eligible for a free registration, you still need to manually go through the payment process although it will have zero charges.', "Continue to complete your project application", {
                        type: "success",
                        confirmButtonText: "Go to My Programs",
                        showCancelButton: true,
                        cancelButtonText: "Close"
                    }).then(() => {
                        this.$router.push('/home/programs');
                    }).catch(() => {
                        // User clicked Cancel, do nothing
                    });
                } else {
                    this.$message.warning(result.msg)
                }
            })
        },

        goBack() {
            this.$router.go(-1)
        },
        showFeeReduction() {
            console.log(this.price_policy, '----')
            if (this.price_policy) {
                this.isShowDialog = true
                //window.open(this.price_policy, "_blank");
            }
        },
        doShowNewsInfo(news) {
            this.selectNews = news
            this.showNewsDialog = true
        },
        handlePaneClick(tab, event) {
            this.activePane = tab.name
        }
    }
})
</script>
<style scoped lang="scss">
.product-content {
    margin-top: 20px;
    width: 100%;
    border: 1px solid #E6D7D7;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 16px;
    display: flex;

    .product-logo {
        width: 80px;
        height: 80px;
        object-fit: cover;
    }

    .middle-content {
        padding: 0px 10px;
        box-sizing: border-box;
        font-size: 14px;

        .price-container {
            border-radius: 5px;
            padding: 6px 10px;
            //box-sizing: border-box;
            background: #F8F8F8;

            .price-date {
                font-family: centurygothic;
            }

            .price {
                font-family: centurygothic;
            }

            .price-reduction {
                margin-top: 10px;
                font-family: centurygothic;
            }

        }


    }

    .right-content {
        flex: 1;
        text-align: right;
        padding: 0px 10px;
        box-sizing: border-box;

        .btn-sign {
            display: inline-block;
            padding: 12px 0;
            width: 110px;
            text-align: center;
            background-color: #ff6450;
            border-radius: 5px;
            box-sizing: border-box;
            color: white;
            font-size: 15px;
            cursor: pointer;
        }

        .desc {
            margin-top: 10px;
            color: #4A0700;
            font-size: 12px;
            font-family: centurygothic;
            line-height: 20px;
        }

        .btn-disabled {
            border-radius: 5px;
            box-sizing: border-box;
            text-align: center;
            padding: 12px 0;
            width: 110px;
            display: inline-block;
            color: #999;
            background-color: #e2e2e2;
            border: 1px solid #e2e2e2;
        }
    }
}


.product_name {
    margin-left: 10px;
    color: #333;
    font-size: 20px;
    font-family: centurygothic_bold;
}

.title {
    color: #333;
    font-size: 16px;
    font-family: centurygothic_bold;
    margin-bottom: 15px;
}

.intro_container {
    border-radius: 5px;
    box-sizing: border-box;
    border: 1px solid #F0E1E1;
    font-size: 14px;
    color: #333;
    line-height: 22px;
    padding: 20px 20px;
    font-family: centurygothic;
}


.announce-container {
    width: 100%;

    .announce-item {
        cursor: pointer;
        background-color: #F8F8F8;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 20px;
        margin-bottom: 20px;

        .announce-time {
            color: var(--c-primary);
            font-size: 14px;
            font-family: centurygothic_bold;
        }

        .announce-title {
            margin-top: 20px;
            color: #333;
            font-size: 14px;
            font-family: centurygothic_bold;
        }

        .announce-content {
            margin-top: 10px;
            color: #999;
            font-size: 12px;
            line-height: 18px;
            font-family: centurygothic;
            white-space: normal;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            /* 定义显示的行数 */
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

}

.font_bold {
    font-weight: bold;
}

.font_red {
    color: var(--c-primary);
}

.font_under_line {
    text-decoration: underline 1px solid var(--c-primary);
    cursor: pointer;
}

.syllabus-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;

    .syllabus-section {
        margin-bottom: 30px;

        h3 {
            color: var(--c-primary);
            font-size: 18px;
            margin-bottom: 15px;
            border-bottom: 2px solid var(--c-primary);
            padding-bottom: 5px;
            font-family: centurygothic_bold;
        }

        ul {
            list-style-type: none;
            padding-left: 0;

            li {
                margin-bottom: 10px;
                font-size: 14px;
                line-height: 1.5;

                span {
                    font-weight: bold;
                    font-family: centurygothic_bold;
                }

                ul {
                    margin-top: 5px;
                    padding-left: 20px;

                    li {
                        font-weight: normal;
                        margin-bottom: 5px;

                        &:before {
                            content: "•";
                            color: var(--c-primary);
                            display: inline-block;
                            width: 1em;
                            margin-left: -1em;
                        }
                    }
                }
            }
        }
    }
}
.syllabus-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;

    .syllabus-section {
        margin-bottom: 30px;

        h3 {
            color: var(--c-primary);
            font-size: 18px;
            margin-bottom: 15px;
            border-bottom: 2px solid var(--c-primary);
            padding-bottom: 5px;
            font-family: centurygothic_bold;
        }

        ul {
            list-style-type: none;
            padding-left: 0;

            li {
                margin-bottom: 10px;
                font-size: 14px;
                line-height: 1.5;

                span {
                    font-weight: bold;
                    font-family: centurygothic_bold;
                }

                ul {
                    margin-top: 5px;
                    padding-left: 20px;

                    li {
                        font-weight: normal;
                        margin-bottom: 5px;

                        &:before {
                            content: "•";
                            color: var(--c-primary);
                            display: inline-block;
                            width: 1em;
                            margin-left: -1em;
                        }
                    }
                }
            }
        }
    }
}

.syllabus-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;

    .syllabus-section {
        margin-bottom: 30px;

        h3 {
            color: var(--c-primary);
            font-size: 18px;
            margin-bottom: 15px;
            border-bottom: 2px solid var(--c-primary);
            padding-bottom: 5px;
        }

        ul {
            list-style-type: none;
            padding-left: 0;

            li {
                margin-bottom: 10px;
                font-size: 14px;
                line-height: 1.5;

                span {
                    font-weight: bold;
                }

                ul {
                    margin-top: 5px;
                    padding-left: 20px;

                    li {
                        font-weight: normal;
                        margin-bottom: 5px;

                        &:before {
                            content: "•";
                            color: var(--c-primary);
                            display: inline-block;
                            width: 1em;
                            margin-left: -1em;
                        }
                    }
                }
            }
        }
    }
}

.timeline-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;

    .timeline-info {
        margin-bottom: 30px;
        font-size: 12px;
        color: #666;

        .info-item {
            margin-bottom: 10px;
            line-height: 1.5;

            i {
                font-style: italic;
            }

            b {
                font-weight: bold;
            }
        }
    }

    .timeline-cycle {
        margin-bottom: 50px;
        

        h3 {
            color: var(--c-primary);
            font-size: 18px;
            margin-bottom: 15px;
            border-bottom: 2px solid var(--c-primary);
            padding-bottom: 10px;
        }

        .el-table {
            margin-top: 15px;
            
            .el-table__header-wrapper th {
                background-color: #f0f0f0;
                color: #333;
                padding: 15px 10px;

                &:first-child {
                    padding-left: 20px;
                }
            }

            .el-table__body-wrapper td {
                padding: 15px 10px;

                &:first-child {
                    padding-left: 20px;
                }
            }
        }
    }

    .exam-times {
        
        
        h3 {
            color: var(--c-primary);
            font-size: 18px;
            margin-bottom: 15px;
            border-bottom: 2px solid var(--c-primary);
            padding-bottom: 10px;
        }

        .el-table {
            margin-top: 15px;

            .el-table__header-wrapper th {
                background-color: #f0f0f0;
                color: #333;
                padding: 15px 10px;
            }

            .el-table__body-wrapper td {
                padding: 15px 10px;
            }
        }
    }
    .timeline-note {
        margin-bottom: 30px;
        padding: 15px;
        background-color: #f9f9f9;
        border-left: 4px solid var(--c-primary);
        border-radius: 4px;

        p {
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 1.5;
            color: #666;

            &:last-child {
                margin-bottom: 0;
            }

            i {
                font-style: italic;
            }

            b {
                font-weight: bold;
                color: #333;
            }
        }
    }
    
}

.sample-questions-container {
    margin-top: 20px;

    .sample-question {
        background-color: #f9f9f9;
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 30px;

        h3 {
            color: var(--c-primary);
            font-size: 18px;
            margin-bottom: 15px;
            border-bottom: 2px solid var(--c-primary);
            padding-bottom: 5px;
        }

        .question {
            font-size: 16px;
            margin-bottom: 15px;
            line-height: 1.5;
        }

        .options {
            list-style-type: none;
            padding-left: 0;

            li {
                margin-bottom: 10px;
                font-size: 14px;
            }
        }

        .correct-answer, .explanation {
            margin-top: 15px;
            font-size: 14px;
            line-height: 1.5;
        }

        .explanation {
            background-color: #e6f7ff;
            border-left: 4px solid #1890ff;
            padding: 10px;
            border-radius: 4px;
        }
    }
}


</style>
